import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import { useAppSelector } from 'stores';

import { privateAgent } from 'config/axios';
import { queryClient } from 'config/queryClient';

import Button from 'components/Button';
import Input from 'components/Input';
import { Modal } from 'components/Modal/useModal';

import BulkTestPrint from '../../others/ProfileAllTestPrint/BulkTestPrint';

const BulkTestModal = () => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [presentRole, setPresentRole] = useState<string>('');
  const { search } = useLocation();
  const user = useAppSelector((state) => state.auth);
  const roleList = useAppSelector((state) => state.auth.user?.role?.role_access);

  const { data, refetch, isFetching, error } = useQuery(
    ['fetch-all-bulk-reports'],
    () =>
      privateAgent.get(
        `test/member/alltest?startDate=${moment(startDate).unix()}&endDate=${
          moment(endDate).unix() + 61200
        }&page=${search.split('=')[1]}&per_page=20&id=${user.user?.id}&fk_role_id=${presentRole}`
      ),
    {
      enabled: false,
      onSuccess: () => toast.success('Report Generated Successfully'),
      onError: (e: any) => toast.error(e.response.data.message),
    }
  );

  const submitHandler = (e: any) => {
    e.preventDefault();
    refetch();
  };
  const componentRef = useRef<HTMLDivElement>(null);

  const clickHandler = useReactToPrint({ content: () => componentRef.current });

  return (
    <Modal>
      <Modal.Button type="open">
        <Button>Print All Report</Button>
      </Modal.Button>
      <Modal.Content
        onModalClose={() => {
          queryClient.removeQueries('fetch-all-bulk-reports');
        }}
      >
        <Modal.Title>Select Date</Modal.Title>
        <form onSubmit={submitHandler} className="space-y-4">
          <div className="flex w-full flex-col ">
            <label className="text-lg font-bold text-gray-500 ">
              Role <span className="text-red-600">*</span>
            </label>
            <select
              onChange={(e) => {
                setPresentRole(e.target.value);
                queryClient.removeQueries('fetch-all-bulk-reports');
              }}
              required
              className='"w-full mt-2 border-2 py-4 px-3 text-xl text-gray-600 '
            >
              <option value="">Please Choose a role </option>
              {roleList?.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>
          <Input
            onChange={(e) => {
              setStartDate(e.target.value);
              queryClient.removeQueries('fetch-all-bulk-reports');
            }}
            value={startDate}
            required
            label="Start Date"
            type="date"
            max={new Date().toISOString().slice(0, -14)}
          />
          <Input
            onChange={(e) => {
              setEndDate(e.target.value);
              queryClient.removeQueries('fetch-all-bulk-reports');
            }}
            value={endDate}
            max={new Date().toISOString().slice(0, -14)}
            required
            label="End Date"
            type="date"
          />

          {(!data || data?.data.data.length === 0 || error) && (
            <Button loading={isFetching}>Generate</Button>
          )}
          {!isFetching && data?.data.data.length > 0 && (
            <Button onClick={clickHandler}> Print </Button>
          )}
          <div className="hidden">
            <BulkTestPrint ref={componentRef} data={data} user={user.user} />
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
};

export default BulkTestModal;
