import React from 'react';
import QRCode from 'react-qr-code';
import moment from 'moment';

import Logo from '../../../../../assets/img/sunya.svg';

type PrintProps = {
  member: {
    id: number;
    gender: string;
    member_code: string;
    name: string;
    email: string;
    phone: string;
    address: string;
    age: number;
  };
};

const MemberProfileToPrint = React.forwardRef<HTMLDivElement, PrintProps>((props, ref) => {
  const { member: primary_details } = props;
  // const qrValue = JSON.stringify({ id: primary_details.id.toString() });

  return (
    <div className="flex h-screen items-center justify-center" ref={ref}>
      <div className=" h-4/5 w-11/12 border border-gray-500 px-5 py-10">
        <div className="flex h-32 justify-between px-3">
          <img className="h-full w-1/2" alt="sunya-logo" src={Logo} />
          <div className="flex ">
            <QRCode size={110} value={primary_details.id.toString()} />
          </div>
        </div>
        <div className="mt-10 flex px-3 pt-10">
          <div className="mt-5 flex-col space-y-8 text-3xl font-medium text-gray-700">
            <h1 className="capitalize">Name : {primary_details.name}</h1>
            <h1>Email : {primary_details.email}</h1>
            <h1>Member Code : {primary_details.member_code}</h1>
            <h1>Address : {primary_details.address}</h1>
            <h1>Gender : {primary_details.gender}</h1>
            <h1>Phone Number : {primary_details.phone}</h1>
            <h1>Age: {primary_details.age} years</h1>
          </div>
        </div>
      </div>
      <div className="page-footer">
        <hr />
        <div className="flex w-full items-center justify-between pt-4">
          <div>
            Printed By Sunya Health ({' '}
            <span className="text-primary-700">{window.location.hostname}</span> ) on{' '}
            {moment(new Date()).format('MMM Do YYYY, h:mm:ss A')}
          </div>

          <span>{primary_details.member_code}</span>
        </div>
      </div>
    </div>
  );
});

MemberProfileToPrint.displayName = 'MemberProfileToPrint';

export default MemberProfileToPrint;
