import { WarningOctagon } from 'phosphor-react';

import { DeviceHistoryQuery } from 'features/members/api/query/useDeviceHistory';
import { Device } from 'features/members/types';

import AndroidLogo from 'assets/img/android.png';

import { toastAlert } from 'components/Alert';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { DeleteModal } from 'components/Modal/DeleteModal';
import { TableView } from 'components/Table';

interface IDeviceHistory {
  member_id: number;
}

export const DeviceHistoryTab = ({ member_id }: IDeviceHistory) => {
  const { data, isLoading } = DeviceHistoryQuery.useList(member_id);
  const { mutateAsync } = DeviceHistoryQuery.useDelete(member_id);

  return (
    <div className="relative flex w-full flex-col rounded-2xl bg-white p-8 shadow-sm">
      <div className="w-full space-y-8 print:hidden sm:w-full">
        <div className="flex items-center justify-between">
          <div className="print:hidden">
            <h1 className="text-3xl font-medium tracking-wider text-gray-900 sm:text-2xl">
              Device History
            </h1>
            <h1 className="text-lg font-medium text-gray-500 print:hidden">
              List of all Devices that user has logged into.
            </h1>
          </div>
          {data && data.length !== 0 ? (
            <>
              <DeleteModal
                title="Remove Device History"
                subTitles={[
                  'This will remove all devices and log you out of all devices you have logged into',
                ]}
                disabled={false}
                closeButton={<Button color="error">Remove History</Button>}
                onDelete={async () => {
                  await toastAlert({
                    type: 'promise',
                    promise: mutateAsync(member_id),
                    msgs: {
                      loading: 'Deleting Device History',
                      success: 'Deleted Device History Successfully',
                    },
                    id: 'device-history',
                  });
                }}
              />
            </>
          ) : null}
        </div>
        {data && data.length !== 0 ? (
          <TableView
            loading={isLoading}
            data={data.sort((a: any, b: any) => (a.id < b.id ? 1 : -1))}
            tableRowComponent={<DeviceHistoryTableRow />}
            tableHeadings={['Device Info', 'Device Model', 'Device Details']}
          />
        ) : (
          <div className="flex  items-center space-x-2 text-xl font-medium text-red-400 ">
            <WarningOctagon size={24} /> <span>No Device Details Found</span>
          </div>
        )}
      </div>
    </div>
  );
};

const DeviceHistoryTableRow = ({ data }: { data?: Device }) => {
  if (!data) {
    return <Loader />;
  }

  return (
    <tr key={data.id}>
      <td className="px-6 py-4 align-top">
        <div className="flex items-center ">
          <div className="relative h-16 w-16 overflow-hidden rounded-full object-contain">
            <img src={AndroidLogo} alt="profile" />
          </div>
          <div className="ml-4 flex flex-col ">
            <div className="text-xl font-medium capitalize  text-gray-900">
              {data.os_type} {data.device_detail['Version Code']}
            </div>
            <div className="text-base font-medium text-gray-500 hover:text-gray-800">
              {data.app_slug}
            </div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-6 py-4 align-top">
        <div className="flex flex-col">
          <div className="text-xl font-medium capitalize  text-gray-700">
            {data.device_detail.Model ?? 'N/A'}
          </div>
          <div className="text-base font-medium capitalize text-gray-500 hover:text-gray-800">
            {data.device_detail.Brand ?? 'N/A'} - {data.device_detail.Board ?? ''}
          </div>
          <div className="text-base font-medium capitalize text-gray-500 hover:text-gray-800">
            SDK : {data.device_detail.SDK ?? 'N/A'}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-6 py-4 align-top">
        <div className="inline">
          <div className="text-base font-medium capitalize text-gray-500 hover:text-gray-800">
            Device Id: {data.device_id === 'none' ? data.device_id : data.device_id.split(' ')[1]}
          </div>
          <div className="text-base font-medium capitalize text-gray-500 hover:text-gray-800">
            Base: {data.device_detail.Base ?? 'N/A'}
          </div>
          <div className="text-base font-medium capitalize text-gray-500 hover:text-gray-800">
            Id: {data.device_detail.ID ?? 'N/A'}
          </div>
          <div className="text-base font-medium capitalize text-gray-500 hover:text-gray-800">
            Host: {data.device_detail.Host ?? 'N/A'}
          </div>
          <div className="text-base font-medium capitalize text-gray-500 hover:text-gray-800">
            Incremental: {data.device_detail.Incremental ?? 'N/A'}
          </div>
          <div className="text-base font-medium capitalize text-gray-500 hover:text-gray-800">
            Manufacture: {data.device_detail.Manufacture ?? 'N/A'}
          </div>
          <div className="text-base font-medium capitalize text-gray-500 hover:text-gray-800">
            User: {data.device_detail.User ?? 'N/A'}
          </div>
          <div className="text-base font-medium capitalize text-gray-500 hover:text-gray-800">
            Type: {data.device_detail.Type ?? 'N/A'}
          </div>
        </div>
      </td>
    </tr>
  );
};
