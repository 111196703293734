import { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { Dialog, Transition } from '@headlessui/react';

import { privateAgent } from 'config/axios';

import NotFoundImage from 'assets/img/not-found.svg';

import Button from 'components/Button';
import Input from 'components/Input';
import Loader from 'components/Loader';

interface SubDetailsprops {
  id: number;
  name: string;
  value_type: string;
}

interface DetailsType {
  fk_m_d_c_id: string;
  value: string;
}

const MemberFilter = ({
  id,
  userid,
  setFilterParams,
}: {
  id: number;
  userid: number;
  setFilterParams: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [details, setDetails] = useState<DetailsType[]>([]);

  const { isLoading, data } = useQuery(['member-secondary-details', id], () =>
    privateAgent.get(`role/secondary_details/${id}`)
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const { value, name } = e.target;

    const newState: any = [...details];
    newState[i] = {
      ...newState[i],
      fk_m_d_c_id: name,
      value,
    };
    setDetails(newState);
  };

  const handleSubmit = () => {
    setIsOpen(false);
    const data = details.filter((el) => el && el?.value !== '');
    let url = `?fk_m_id=${userid}`;
    data.forEach(
      (item, index: number) =>
        (url += `&data[${index}][fk_m_d_c_id]=${item.fk_m_d_c_id}&data[${index}][value]=${item.value}`)
    );

    setFilterParams(url);
  };

  return (
    <>
      <Button type="button" onClick={() => setIsOpen(true)}>
        Filter Members
      </Button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          onClose={() => setIsOpen(false)}
          as="div"
          className="fixed right-0 top-0 z-50 overflow-y-auto"
        >
          <div className="min-h-screen text-center">
            {isOpen && (
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-100"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
              </Transition.Child>
            )}

            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500 "
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave=" ease-in-out duration-200 "
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="flex h-screen w-full max-w-7xl transform flex-col space-y-10 overflow-hidden bg-white px-12 py-12 text-left align-middle shadow-xl transition">
                <div className="flex w-[50rem] flex-col gap-8">
                  <div className="flex flex-col gap-2">
                    <Dialog.Title
                      as="h3"
                      className="text-4xl font-medium capitalize leading-6 text-gray-700"
                    >
                      Filter Members By Category
                    </Dialog.Title>
                    <span className="text-lg text-gray-400">
                      Please select any of filters below
                    </span>
                    {isLoading ? (
                      <Loader />
                    ) : data?.data.data.length <= 0 ? (
                      <div className="mt-8 flex flex-col items-center justify-center">
                        <img
                          src={NotFoundImage}
                          className="relative h-32 w-full self-start object-contain"
                        />
                        <div className="flex flex-col items-center">
                          <div className="text-3xl font-medium text-primary-600">No Data found</div>
                          <div className="text-lg font-medium text-gray-500">
                            No Filtration fields found for given role
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="flex w-full flex-wrap gap-5">
                          {data?.data.data.map((el: SubDetailsprops, index: number) => (
                            <div style={{ width: '47%' }} key={el.id} className="mt-3">
                              <Input
                                value={details[index]?.value || ''}
                                name={el.id.toString()}
                                type={el.value_type === 'string' ? 'text' : 'number'}
                                label={el.name}
                                onChange={(e) => handleChange(e, index)}
                              />
                            </div>
                          ))}
                        </div>
                        <div className="mt-5 flex justify-end gap-5">
                          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                          <Button
                            onClick={() => {
                              setDetails([]);
                              setFilterParams('');
                            }}
                          >
                            Reset
                          </Button>
                          <Button onClick={handleSubmit}>Filter</Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default MemberFilter;
