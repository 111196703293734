import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'phosphor-react';

type PaginationObject = {
  total: number;
  count: number;
  path: string;
  per_page: number;
  current_page: number;
  total_pages: number;
};

type paginationProps = {
  totalPageNumber: number;
  pageIndex: number;
  setPageIndex: any;
  pageName?: string;
  paginateObject?: PaginationObject;
};

export const Pagination = ({
  totalPageNumber,
  pageIndex,
  setPageIndex,
  pageName,
}: paginationProps): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Pagination States for Handling Form Input and UI
  const [pageArray, setPageArray] = useState([1, 2, 3, 4, 5]);
  const [enteredPage, setEnteredPage] = useState<number | ''>(1);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  // Incase props are not mentioned. Used for storybook
  const [pageDIndex, setPageDIndex] = useState(1);
  pageIndex = !pageIndex ? pageDIndex : pageIndex;
  setPageIndex = !setPageIndex ? setPageDIndex : setPageIndex;

  const leftClickHandler = () => {
    if (pageIndex <= 1) {
      setPageIndex(1);
      searchParams.set('page', `${1}`);
      setSearchParams(searchParams);
      return;
    }
    setPageIndex(pageIndex - 1);
    searchParams.set('page', `${pageIndex - 1}`);
    setSearchParams(searchParams);
  };

  const rightClickHandler = () => {
    if (pageIndex >= totalPageNumber) {
      setPageIndex(totalPageNumber);
      searchParams.set('page', `${totalPageNumber}`);
      setSearchParams(searchParams);

      return;
    }
    setPageIndex(pageIndex + 1);
    searchParams.set('page', `${pageIndex + 1}`);
    setSearchParams(searchParams);
  };
  useEffect(() => {
    const page = +pageIndex;
    if (page < 1) {
      setPageIndex(1);
      return;
    }
    setPageIndex(+pageIndex);

    if (totalPageNumber <= 6) {
      setPageArray(() => {
        const tempPageArray = [];
        for (let i = 1; i <= totalPageNumber; i++) tempPageArray.push(i);
        return tempPageArray;
      });
      return;
    }

    if (page > 3 && page <= totalPageNumber - 3)
      setPageArray([page - 2, page - 1, page, page + 1, page + 2]);
    else if (page <= 4) setPageArray([page, page + 1, page + 2, page + 3, page + 4]);
    else setPageArray([page - 4, page - 3, page - 2, page - 1, page]);
  }, [pageIndex, totalPageNumber, setPageIndex]);

  return (
    <div className="mx-auto flex items-center justify-center leading-6 text-gray-800">
      <div className="flex items-center space-x-8 rounded-xl px-2 py-1 text-lg font-bold sm:flex-col sm:space-x-2 sm:space-y-4 sm:px-0 sm:text-xl">
        <ul className="flex items-center space-x-2 sm:space-x-2">
          <li>
            <button className="button-nav" onClick={leftClickHandler} disabled={pageIndex === 1}>
              <ArrowLeft size={24} />
            </button>
          </li>

          {pageArray.map((page) => (
            <li
              key={page}
              className={pageIndex !== page ? 'inactive_item' : 'active_item'}
              onClick={() => {
                searchParams.set('page', `${page}`);
                setSearchParams(searchParams);
                setPageIndex(page);
              }}
            >
              {page}
            </li>
          ))}

          <li>
            <button
              className="button-nav"
              disabled={pageIndex === totalPageNumber}
              onClick={rightClickHandler}
            >
              <ArrowRight size={24} />
            </button>
          </li>
        </ul>
        <form
          className="flex items-center space-x-4 text-gray-500 sm:text-xl"
          onSubmit={(e) => {
            e.preventDefault();

            if (enteredPage && enteredPage <= 1) {
              setEnteredPage(1);
              setPageIndex(1);
              navigate({
                pathname: location.pathname,
                hash: location.hash,
                search: `${location.search}/page=1`,
              });

              return;
            }
            if (enteredPage && enteredPage >= totalPageNumber) {
              setEnteredPage(totalPageNumber);
              setPageIndex(totalPageNumber);
              navigate({
                pathname: location.pathname,
                hash: location.hash,
                search: `${location.search}/page=${totalPageNumber}`,
              });

              return;
            }
            setPageIndex(enteredPage);
          }}
        >
          <div>Go To Page</div>
          <input
            onChange={(e) => {
              if (+e.target.value === 0) {
                setEnteredPage('');
                return;
              }
              setEnteredPage(+e.target.value);
            }}
            type="number"
            value={enteredPage.toString()}
            className="ring-primary_gray-500/50 w-14 rounded-lg bg-gray-100 py-xs px-4 text-center shadow-sm ring-1"
          />
          <button className="flex cursor-pointer items-center font-bold hover:text-gray-800">
            <span>Go</span>
          </button>
        </form>
      </div>
    </div>
  );
};
