import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import Select from 'react-select';
import { RoleAccess } from 'types/RoleTypes';

import Button from '../../../../../components/Button/index';
import Input from '../../../../../components/Input/index';
import { Modal } from '../../../../../components/Modal/useModal';

import { privateAgent } from '../../../../../config/axios';

interface RolesProps {
  rolesData: RoleAccess[] | undefined;
}

type RoleData = {
  email: string;
};
/**
 *
 * @param {"add" | "edit"} type
 * @param {Member} initialData
 * @param {React.ReactNode} button
 * @param {Role} selectedRole
 * @return {JSX.Element}
 */
export function InviteModal({ rolesData }: RolesProps) {
  const [roles, setRoles] = useState('');

  const options = rolesData?.map((el) => ({
    value: el.id.toString(),
    label: el.name,
  }));

  const mutation = useMutation((val: string) => {
    return privateAgent.post('member/inviteMember', { email: val, role_id: Number(roles) });
  });

  const { register, handleSubmit, reset } = useForm<RoleData>({});
  useEffect(() => {
    if (mutation.isSuccess) {
      toast.success('Invited Successfully');
      reset();
      setRoles('');
    }
  }, [mutation.isSuccess]);
  useEffect(() => {
    if (mutation.isError) {
      toast.error('Something went wrong');
    }
  }, [mutation.isError]);

  const onSubmit = (val: { email: string }): void => {
    if (!val.email || !roles) {
      toast.error('All fields are required');
    } else {
      mutation.mutate(val.email);
    }
  };

  return (
    <Modal>
      <Modal.Button type="open">
        <Button>Invite Members</Button>
      </Modal.Button>

      <Modal.Content
        width="max-w-4xl"
        onModalClose={() => {
          reset();
        }}
      >
        <Modal.Title>Invite Member</Modal.Title>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 space-y-4 py-7 ">
          <div>
            <label className="text-gray-700 ">
              Role <span className="text-red-500">*</span>
            </label>
            <Select
              onChange={(e) => {
                if (e) {
                  setRoles(e.value);
                }
              }}
              placeholder="Select Role"
              className="mt-3"
              options={options}
            />
          </div>

          <Input type="email" required {...register('email')} label="Email Address" />
          <div>
            <Button loading={mutation.isLoading} type="submit">
              Invite
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
}
