import { useQuery } from 'react-query';

import { privateAgent } from 'config/axios';

const getDashboardData = (url: string) =>
  privateAgent.get<any>(url).then((response) => {
    return response.data.data;
  });

export const useDashboardQuery = (id: number, roleData: any) => {
  const schoolOperator = roleData.find((el: any) => el.slug === 'school_operator');
  const schoolStudent = roleData.find((el: any) => el.slug === 'school_student');

  return useQuery(
    'dashboard',
    () =>
      getDashboardData(
        `/dashboard/org_overview/${id}?child_role_id=${schoolStudent.id}&operator_role_id=${schoolOperator.id}`
      ),
    {
      staleTime: Infinity,
    }
  );
};
