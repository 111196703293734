import React from 'react';

export type StatCardProps = {
  icon: React.ReactNode;
  value: number | string;
  valueText: string | string[];
};
export const StatCard: React.FC<StatCardProps> = ({ icon, value, valueText }) => {
  return (
    <div className=" flex flex-col items-center space-y-6 rounded-lg bg-white px-4 py-8 shadow-sm transition-all duration-200 hover:scale-110 hover:shadow-lg ">
      {icon}

      <div className=" flex flex-col items-center space-y-2">
        <p className=" text-5xl font-medium text-gray-800">{value}</p>
        <h1 className=" text-center text-xl font-medium text-gray-500 sm:text-xl">{valueText}</h1>
      </div>
    </div>
  );
};
