import React from 'react';
import groupBy from 'lodash/groupBy';
import moment from 'moment';

import { utcDateToLocal } from 'features/members/utils/utcDateToLocal';

import SunyaLogo from 'assets/img/sunya.svg';

import { URINE_ORDER, URINE_TEST } from '../../table/ProfileTestTableRow';

interface PrintTestComponentProps {
  data: any;
  user: any;
}

const testDemo = {
  Urine: [
    {
      test_date: 1666161369,
      test_name: 'Urine',
      app_slug: 'jivan_suchak',
      tests: [
        {
          Alb: 'High 150.0',
          Note: [],
          unit: 'n/a',
        },
        {
          bil: 'Normal neg.',
          Note: [],
          unit: 'n/a',
        },
        {
          blo: 'Moderate 80.0',
          Note: [],
          unit: 'n/a',
        },
        {
          ca: 'Normal ≤1.0',
          Note: [],
          unit: 'n/a',
        },
        {
          cre: 'Normal 0.9',
          Note: [],
          unit: 'n/a',
        },
        {
          glu: 'Moderate 30.0',
          Note: [],
          unit: 'n/a',
        },
        {
          ket: 'Normal neg.',
          Note: [],
          unit: 'n/a',
        },
        {
          leu: 'Mild 70.0',
          Note: [],
          unit: 'n/a',
        },
        {
          nit: 'Normal neg.',
          Note: [],
          unit: 'n/a',
        },
        {
          ph: 'High (Basic) 8.5',
          Note: [],
          unit: 'n/a',
        },
        {
          pro: 'Moderate 3.0',
          Note: [],
          unit: 'n/a',
        },
        {
          sg: 'Moderate 1.025',
          Note: [],
          unit: 'n/a',
        },
        {
          uro: 'Normal 3.3',
          Note: [],
          unit: 'n/a',
        },
        {
          vc: 'High 5.0',
          Note: [],
          unit: 'n/a',
        },
      ],
    },
  ],
  Acuity: [
    {
      test_date: 1666161050,
      test_name: 'Acuity',
      app_slug: 'jivan_suchak',
      tests: [
        {
          'Acuity Score': '6/6\n',
          Note: [],
          unit: 'n/a',
        },
      ],
    },
  ],
  ECG: [
    {
      test_date: 1666161015,
      test_name: 'ECG',
      app_slug: 'jivan_suchak',
      tests: [
        {
          'Heart rate': '84',
          Note: [],
          unit: 'n/a',
        },
        {
          mrr: '719.41',
          Note: [],
          unit: 'n/a',
        },
        {
          qrs: '73',
          Note: [],
          unit: 'n/a',
        },
        {
          qt: '321',
          Note: [],
          unit: 'n/a',
        },
        {
          qtc: '364',
          Note: [],
          unit: 'n/a',
        },
        {
          report_link:
            'https://sunyahealth.sgp1.digitaloceanspaces.com/Sunya_Health/assets/report/ecg/785/19-10-2022_12_14_45.pdf',
          Note: [],
          unit: 'n/a',
        },
        {
          rmssd: '6.46',
          Note: [],
          unit: 'n/a',
        },
        {
          sdnn: '93.78',
          Note: [],
          unit: 'n/a',
        },
        {
          finding: 'Normal',
          Note: [],
          unit: 'n/a',
        },
      ],
    },
    {
      test_date: 1666161015,
      test_name: 'ECG',
      app_slug: 'jivan_suchak',
      tests: [
        {
          'Heart rate': '84',
          Note: [],
          unit: 'n/a',
        },
        {
          mrr: '719.41',
          Note: [],
          unit: 'n/a',
        },
        {
          qrs: '73',
          Note: [],
          unit: 'n/a',
        },
        {
          qt: '321',
          Note: [],
          unit: 'n/a',
        },
        {
          qtc: '364',
          Note: [],
          unit: 'n/a',
        },
        {
          report_link:
            'https://sunyahealth.sgp1.digitaloceanspaces.com/Sunya_Health/assets/report/ecg/785/19-10-2022_12_14_45.pdf',
          Note: [],
          unit: 'n/a',
        },
        {
          rmssd: '6.46',
          Note: [],
          unit: 'n/a',
        },
        {
          sdnn: '93.78',
          Note: [],
          unit: 'n/a',
        },
        {
          finding: 'Normal',
          Note: [],
          unit: 'n/a',
        },
      ],
    },
  ],
  Glucose: [
    {
      test_date: 1666160875,
      test_name: 'Glucose',
      app_slug: 'jivan_suchak',
      tests: [
        {
          'glucose score': '170.99',
          Note: [
            'Test Taken: After Meal',
            'Latest Meal: Lunch',
            '"Meal Taken Time: " 10:00 pm',
            'Meal Description: ',
            'Sn Device: 00540006901945894E45',
          ],
          unit: 'mg/dL',
        },
      ],
    },
  ],
  Vitals: [
    {
      test_date: 1666160744,
      test_name: 'Vitals',
      app_slug: 'jivan_suchak',
      tests: [
        {
          'Blood Pressure': '114/79',
          Note: [],
          unit: 'n/a',
        },
        {
          'Heart Beat': '54',
          Note: [],
          unit: 'n/a',
        },
        {
          'Hrv Sdnn': 'N/A',
          Note: [],
          unit: 'n/a',
        },
        {
          'Oxygen saturation': '98',
          Note: [],
          unit: 'n/a',
        },
        {
          respiration: '22',
          Note: [],
          unit: 'n/a',
        },
        {
          'stress level': 'N/A',
          Note: [],
          unit: 'n/a',
        },
      ],
    },
  ],
  'Body Mass Index': [
    {
      test_date: 1666160632,
      test_name: 'Body Mass Index',
      app_slug: 'jivan_suchak',
      tests: [
        {
          'Bmi Score': '22.72',
          Note: ['Bmi Category: Healthy Weight', 'Age: 21', 'Height: 5 ft 7 in', 'Weight: 65.8kg'],
          unit: 'n/a',
        },
      ],
    },
  ],
};

type PrintProps = React.DetailedHTMLProps<React.AllHTMLAttributes<HTMLDivElement>, HTMLDivElement> &
  PrintTestComponentProps;

const arrToObj = (data: any) => {
  const subTestDetails = data
    ? data.map((element: any) => {
        return Object.assign(
          {},
          {
            test_date: element.test_date,
            test_name: element.test_name,
            app_slug: element.app_slug,
            temperature: element.temperature,

            tests: element.report.map((sub: any) => ({
              [sub.name]: sub.value,
              [`Note`]: sub.note,
              unit: sub.unit,
            })),
          }
        );
      })
    : [];

  const subTestGroups = groupBy(subTestDetails, 'test_name');
  return subTestGroups;
};

// eslint-disable-next-line react/display-name
const BulkTestPrint = React.forwardRef<HTMLDivElement, PrintProps>(({ data, user }, ref) => {
  // const glucoseLists = [
  //   'Test Taken',
  //   'Latest Meal',
  //   'Meal Taken',
  //   'Meal Description',
  //   'SN Device',
  //   'mol/DL',
  // ];

  return (
    <div className="w-full" ref={ref}>
      {/* <div className="page-footer">
        <hr />
        <p>For screening purposes only. Not for use in diagnostic procedures</p>
        <div className="flex w-full items-center  justify-between">
          <div>
            Printed By Sunya Health ({' '}
            <span className="text-primary-700">{window.location.hostname}</span> ) on{' '}
            {moment(new Date()).format('MMM Do YYYY, h:mm:ss A')}
          </div>

          <span>{user.member_code}</span>
        </div>
      </div> */}

      <table className="w-full">
        <thead>
          <tr>
            <td></td>
          </tr>
        </thead>
        <tbody className="w-screen">
          <tr>
            <td>
              {data?.data.data.map((el: any, index: number) => (
                <div className="page" key={el.member.id}>
                  <div className="flex w-full flex-col">
                    <div className="flex  w-full items-center  justify-between">
                      <div className="flex flex-col">
                        <h1 className="text-2xl font-medium tracking-wider text-gray-900">
                          Test Report
                        </h1>
                        {el.member && (
                          <>
                            <div className="mt-3 flex flex-wrap gap-3">
                              <h1 className="text-lg font-medium capitalize tracking-wider text-gray-900">
                                Name: {el.member.name}
                              </h1>
                              <h1 className="text-lg font-medium tracking-wider text-gray-900 ">
                                Gender: {el.member.gender}
                              </h1>
                              <h1 className="text-lg font-medium tracking-wider text-gray-900 ">
                                Age: {el.member.age} years
                              </h1>

                              <h1 className="text-lg font-medium tracking-wider text-gray-900 ">
                                Address: {el.member.address}
                              </h1>
                              <h1 className="text-lg font-medium tracking-wider text-gray-900 ">
                                Org. Name: {user?.name}
                              </h1>
                            </div>
                            <div className="mt-1">
                              <p className="text-gray-700">
                                Note: *For screening purposes only. Not for use in diagnostic
                                procedures
                              </p>
                            </div>
                          </>
                        )}
                      </div>

                      <div className={`flex  flex-col items-center self-center`}>
                        <img width={100} height={100} alt="Profile Image" src={SunyaLogo} />
                        <h1 className="pt-3 text-sm font-medium tracking-wider text-slate-600 ">
                          Kathmandu, Nepal
                        </h1>
                        <h1 className="pt-1 text-sm font-medium tracking-wider text-slate-600 ">
                          contact@sunya.health
                        </h1>
                      </div>
                    </div>
                  </div>

                  {Object.keys(arrToObj(el.tests)).map((element, index) => (
                    <div key={element}>
                      <div className="mt-5 w-full space-y-2 whitespace-nowrap  bg-blue-100 p-1  text-lg font-medium capitalize text-black">
                        {element} Test
                      </div>
                      {arrToObj(el.tests)[element]?.map((report: Record<any, any>) => (
                        <div
                          key={report.test_date}
                          className="mt-1 flex px-4 py-3  text-lg capitalize"
                        >
                          {element === 'Urine' ? (
                            <>
                              <div>
                                <span className="block">
                                  {moment(utcDateToLocal(report.test_date)).format('MM/DD/YYYY')}
                                </span>
                                <span className="mt-2 block">
                                  {moment(utcDateToLocal(report.test_date)).format('h:mm A')}
                                </span>
                              </div>
                              <div className="ml-5  flex flex-wrap gap-2">
                                {URINE_ORDER.map((element, index) => {
                                  const unit = report.tests.find((test: any) =>
                                    Object.keys(test).find(
                                      (sub_test) =>
                                        sub_test?.toLowerCase() === element?.toLowerCase()
                                    )
                                  )?.unit;

                                  const value = report.tests.find((test: any) =>
                                    Object.keys(test).find(
                                      (sub_test) =>
                                        sub_test?.toLowerCase() === element?.toLowerCase()
                                    )
                                  )?.[element];

                                  return (
                                    value?.toLowerCase() !== 'n/a' && (
                                      <div
                                        style={{ width: '31%' }}
                                        key={index}
                                        className="flex w-full flex-wrap  "
                                      >
                                        <span>{URINE_TEST[element]} : </span>
                                        <span>{value?.split(' ')[0]}</span>
                                        <span className="font-medium lowercase">
                                          {unit === 'n/a' ? '' : unit}
                                        </span>
                                      </div>
                                    )
                                  );
                                })}
                              </div>
                            </>
                          ) : element === 'Glucose' ? (
                            <>
                              <div>
                                <span className="block">
                                  {moment(utcDateToLocal(report.test_date)).format('MM/DD/YYYY')}
                                </span>
                                <span className="mt-2 block">
                                  {moment(utcDateToLocal(report.test_date)).format('h:mm A')}
                                </span>
                              </div>
                              <div>
                                {report.tests.map((value: Record<any, any>, index: number) => (
                                  <div className="ml-5   flex flex-wrap gap-2" key={index}>
                                    <div>
                                      <span>
                                        {Object.keys(value)[0] !== 'report_link' &&
                                          `${Object.keys(value)[0]} :`}
                                      </span>
                                      <span>
                                        {Object.keys(value)[0] !== 'report_link' &&
                                          Object.values(value)[0]}
                                      </span>
                                    </div>
                                    <div className="flex flex-wrap gap-4">
                                      {Object.values(value)[1].map(
                                        (element: any, index: number) => (
                                          <span className="flex" key={element}>
                                            {index < 4 &&
                                              element.split(':')[1] != ' ' &&
                                              element.split(':')[1] != '' &&
                                              element}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : element == 'Acuity' ? (
                            <>
                              <div>
                                <span className="block">
                                  {moment(utcDateToLocal(report.test_date)).format('MM/DD/YYYY')}
                                </span>
                                <span className="mt-2 block">
                                  {moment(utcDateToLocal(report.test_date)).format('h:mm A')}
                                </span>
                              </div>
                              <div className="ml-5 flex w-full flex-wrap gap-5">
                                {report.tests.map((value: Record<any, any>, index: number) => (
                                  <div style={{ width: '30%' }} key={index}>
                                    <span className="flex">
                                      {Object.keys(value)[0]} : {Object.values(value)[0]}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : element !== 'Body Mass Index' ? (
                            <>
                              <div>
                                <span className="block">
                                  {moment(utcDateToLocal(report.test_date)).format('MM/DD/YYYY')}
                                </span>
                                <span className="mt-2 block">
                                  {moment(utcDateToLocal(report.test_date)).format('h:mm A')}
                                </span>
                              </div>
                              <div className="ml-5   flex flex-wrap gap-2">
                                {report.tests.map((value: Record<any, any>, index: number) => (
                                  <div style={{ width: '31%' }} key={index}>
                                    <span>
                                      {Object.keys(value)[0] !== 'report_link' &&
                                        `${Object.keys(value)[0]} :`}
                                    </span>
                                    <span>
                                      {Object.keys(value)[0] !== 'report_link' &&
                                        Object.values(value)[0]}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                <span className="block">
                                  {moment(utcDateToLocal(report.test_date)).format('MM/DD/YYYY')}
                                </span>
                                <span className="mt-2 block">
                                  {moment(utcDateToLocal(report.test_date)).format('h:mm A')}
                                </span>
                              </div>
                              <div>{report.temperature && <>{report.temperature} &deg; F </>}</div>
                              <div>
                                {report.tests.map((value: Record<any, any>, index: number) => (
                                  <div className="ml-5   flex flex-wrap gap-2" key={index}>
                                    <div>
                                      <span>
                                        {Object.keys(value)[0] !== 'report_link' &&
                                          `${Object.keys(value)[0]} :`}
                                      </span>
                                      <span>
                                        {Object.keys(value)[0] !== 'report_link' &&
                                          Object.values(value)[0]}
                                      </span>
                                    </div>
                                    <div className="flex flex-wrap gap-2">
                                      {Object.values(value)[1].map(
                                        (element: any, index: number) => (
                                          <span className="flex" key={element}>
                                            {element !== 'Bmi Percentile: ' && element}{' '}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </td>
          </tr>
        </tbody>
        {/* <tfoot>
          <tr>
            <td>
              <div className="page-footer-space"></div>
            </td>
          </tr>
        </tfoot> */}
      </table>
    </div>
  );
});

export default BulkTestPrint;
