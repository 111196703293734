import { Health, People, Profile2User, SecurityUser } from 'iconsax-react';

import { StatCard } from './StatCard';

type StatCardGroupProps = {
  data: any;
  user?: any;
};

const StatCardGroup: React.FC<StatCardGroupProps> = ({ data }) => {
  return (
    <div className="grid h-auto grid-cols-5 gap-6 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 sm:gap-4">
      <StatCard
        icon={
          <div className="rounded-full bg-orange-600 p-8">
            <Profile2User variant="Broken" size={36} color="#fff" />{' '}
          </div>
        }
        value={data?.total_members}
        valueText="Total Members"
      />
      <StatCard
        icon={
          <div className="rounded-full bg-blue-600 p-8">
            <SecurityUser variant="Broken" size={36} color="#fff" />{' '}
          </div>
        }
        value={data?.total_patient}
        valueText="Total Patients"
      />
    </div>
  );
};

export const StatCardGroupVariant2: React.FC<StatCardGroupProps> = ({ data, user }) => {
  return (
    <div className={`grid  w-full ${user?.id ? 'grid-cols-2' : ''} gap-6 lg:grid-cols-1 sm:gap-4`}>
      <div className={`flex ${user?.id ? 'flex-col' : ''}   h-full gap-6`}>
        <StatCardRect
          icon={
            <div className="rounded-full bg-lime-600 p-8">
              <Health variant="Broken" size={36} color="#fff" />
            </div>
          }
          value={data.total_test_taken}
          valueText={['Total Test Count', 'Total Tests taken through Sunya Apps']}
        />
        <StatCardRect
          icon={
            <div className="rounded-full bg-slate-700 p-8">
              <People size={36} color="#fff" variant="Broken" />
            </div>
          }
          value={data.organization_operator}
          valueText={['Organization Operators', 'Total Operators added by Organizations']}
        />
      </div>
    </div>
  );
};
const StatCardRect: React.FC<any> = ({ icon, value, valueText }) => {
  return (
    <div
      className=" flex w-full items-center justify-between rounded-lg bg-white py-8 px-8 shadow-sm transition-all duration-200 hover:scale-105 hover:shadow-lg
                  sm:py-4"
    >
      <div className="flex items-center gap-6 sm:gap-4">
        {icon}
        <div className="flex flex-col">
          <h1 className="text-3xl font-medium text-gray-800 sm:text-xl">{valueText[0]}</h1>
          <p className="text-center text-lg font-medium text-gray-500 sm:text-left">
            {valueText[1]}
          </p>
        </div>
      </div>
      <p className="text-5xl font-medium text-gray-800">{value} </p>
    </div>
  );
};

export default StatCardGroup;
