import type { ReactElement } from 'react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { MagnifyingGlass, X } from 'phosphor-react';

import { PaginationObject } from 'features/members/api/requests/member.request';

import NotFoundImage from 'assets/img/not-found.svg';

import Input from 'components/Input';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination';

type TableViewPropsType = {
  data: Array<{ [key: string]: any }> | any;
  width?: 'full' | string;
  searchTerms?: string[];
  setFilterParams?: any;
  tableHeadings?: string[];
  tableRowComponent?: ReactElement;
  loading?: boolean;
  search?: boolean;
  paginate?: boolean;
  paginateObject?: PaginationObject;
  filterComponent?: React.ReactNode;
  error?: boolean;
  searchTerm?: string;
  setSearchTerm?: any;
};

export const TableView: React.FC<TableViewPropsType> = React.memo(
  ({
    data,
    tableHeadings,
    tableRowComponent,
    loading,
    search = false,
    paginate = false,
    paginateObject,
    filterComponent,
    searchTerm,
    setSearchTerm,
    setFilterParams,
    error,
  }) => {
    const params = useParams();
    const [pageIndex, setPageIndex] = useState(params.page ? +params.page : 1);

    const headings = tableHeadings ? tableHeadings : data.length !== 0 && Object.keys(data[0]);

    return (
      <div>
        <div className="flex flex-col  space-y-2">
          <div className="flex items-center justify-between">
            {search && (
              <div className="relative ml-1 flex space-x-6 print:hidden md:w-1/2">
                <Input
                  value={searchTerm}
                  onChange={(e) => {
                    setFilterParams('');
                    if (e.target.value === '') {
                      setSearchTerm(e.target.value);
                      return;
                    }
                    setSearchTerm(e.target.value);
                  }}
                  placeholder="Start Searching ...."
                />
                <div
                  className="absolute right-4 top-[48%] -translate-y-[50%]"
                  onClick={() => {
                    setSearchTerm('');
                  }}
                >
                  {searchTerm !== '' ? (
                    <X
                      stroke="transparent"
                      className="cursor-pointer stroke-2 text-gray-500 hover:text-gray-700 peer-focus-visible:text-red-500"
                      size={24}
                    />
                  ) : (
                    <MagnifyingGlass
                      stroke="transparent"
                      className="cursor-pointer stroke-2 text-gray-500 hover:text-gray-700 peer-focus-visible:text-red-500"
                      size={24}
                    />
                  )}
                </div>
              </div>
            )}
            {filterComponent}
          </div>

          {loading ? (
            <Loader />
          ) : !data || data.length === 0 || error ? (
            <div className="flex flex-col items-center justify-start gap-4 py-32">
              <img src={NotFoundImage} className="relative h-32 w-full self-start object-contain" />

              <div className="flex flex-col items-center">
                <div className="text-3xl font-medium text-primary-600">No Data found</div>
                <div className="text-lg font-medium text-gray-500">
                  Please change your search to find more data or data does not exist.
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col">
              {paginate && paginateObject && paginateObject.total_pages > 1 && (
                <div className="pt-2">
                  <Pagination
                    totalPageNumber={paginateObject.total_pages}
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                  />
                </div>
              )}
              <div className="print:sidebar -mx-1 md:overflow-x-auto md:overflow-y-auto">
                <div className="inline-block min-w-full px-1 py-2 align-middle print:p-0">
                  <div className="rounded-sm border-b border-gray-200 shadow-E100 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-100">
                        <tr>
                          {headings &&
                            headings.map((heading, index) => (
                              <th
                                scope="col"
                                key={index}
                                className="px-6 pb-3 pt-4 text-left text-base font-medium uppercase tracking-wider text-gray-600"
                              >
                                {heading}
                              </th>
                            ))}
                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200 bg-white">
                        {tableRowComponent
                          ? loading === false
                            ? data.map((data: any) =>
                                React.cloneElement(tableRowComponent, {
                                  key: data.id,
                                  data,
                                  loading,
                                })
                              )
                            : React.cloneElement(tableRowComponent, {
                                loading,
                              })
                          : data.map((data: any, index: number) => {
                              return (
                                <tr key={index}>
                                  {Object.values(data).map((d: any, index) => (
                                    <td
                                      key={index}
                                      className="whitespace-nowrap px-6 py-4 text-xl font-medium capitalize text-gray-850"
                                    >
                                      {d.toString()}
                                    </td>
                                  ))}
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {paginate && paginateObject && paginateObject.total_pages > 1 && (
            <div className="pt-2">
              <Pagination
                totalPageNumber={paginateObject.total_pages}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);

TableView.displayName = 'TableView';
